@import '../../../../scss/theme-bootstrap';

.gnav-custom {
  // Delete height of gnav
  background-repeat: no-repeat;
  &.gnav-height {
    .site-content {
      margin-top: -$navbar-height-default-mob;
      @include breakpoint($landscape-up) {
        margin-top: -$navbar-height-default;
      }
    }
    // Delete height of gnav with enlarged logo
    &.site-header-formatter--large-logo {
      .site-content {
        margin-top: calc(#{$navbar-height-large-logo-mob} * -1);
        @include breakpoint($landscape-up) {
          margin-top: calc(#{$navbar-height-large-logo} * -1);
        }
      }
    }
  }

  // DARK style is default
  // Transparent toggle
  &.transparent-toggle {
    .site-header {
      background-color: transparent;
      &-formatter__inner,
      &-formatter__bar {
        background-color: transparent;
      }
    }
  }
  // undo BG color changes for sticky nav
  &.site-header-formatter--sticky {
    .site-header-formatter__inner,
    .site-header-formatter__bar {
      background-color: $color-white;
    }
  }
  &.transparent-toggle:not(.site-header-formatter--sticky) {
    .site-header-formatter__inner,
    .site-header-formatter__bar {
      background-color: transparent;
    }
  }

  // LIGHT style icon and link color overrides
  &.gnav-custom--light {
    .site-header-formatter__logo svg,
    .site-header-formatter__menu-icon svg,
    .site-header-formatter__search-icon svg,
    .site-header-formatter__locator-link svg,
    .site-header-formatter__search-icon svg,
    .gnav-util__icon svg,
    .gnav-cart svg,
    .gnav-account__icon svg,
    .site-utils__icon--search,
    .gnav-cart__link .icon--bag {
      fill: $color-white;
    }
    .site-header-formatter__menu-icon span {
      color: $color-white;
    }
    a.header-nav-section__section-label,
    label.header-nav-section__section-label,
    .site-header-formatter__section:not(.content-panel-visible) label.header-nav-section__section-label,
    .header-nav-section__section-label label,
    .gnav-cart .gnav-cart__link span,
    .site-header-formatter__utilities-link,
    .gnav-utility-item a.gnav-utility-item__trigger-label span {
      color: $color-white;
      @include breakpoint($landscape-up) {
        &:hover,
        &:visited {
          box-shadow: 0 1px 0 0 $color-white;
          color: $color-white;
        }
      }
    }
    .gnav-cart__link,
    .gnav-utility-item__trigger-label {
      color: $color-white;
      &:hover,
      &:visited {
        color: $color-white;
      }
    }

    // undo LIGHT color changes for sticky nav
    &.site-header-formatter--sticky {
      .site-header-formatter__logo svg,
      .site-header-formatter__menu-icon svg,
      .site-header-formatter__search-icon svg,
      .site-header-formatter__locator-link svg,
      .gnav-util__icon svg,
      .gnav-cart .gnav-cart__link svg,
      .gnav-account__icon svg,
      .site-utils__icon--search,
      .gnav-cart__link .icon--bag {
        fill: $color-black;
      }
      .site-header-formatter__menu-icon span {
        background: $color-black;
      }
      a.header-nav-section__section-label,
      label.header-nav-section__section-label,
      .site-header-formatter__section:not(.content-panel-visible) label.header-nav-section__section-label,
      .header-nav-section__section-label label,
      .gnav-cart .gnav-cart__link span,
      .site-header-formatter__utilities-link,
      .gnav-utility-item a.gnav-utility-item__trigger-label span {
        color: $color-black;
        @include breakpoint($landscape-up) {
          &:hover,
          &:visited {
            box-shadow: 0 1px 0 0 $color-black;
            color: $color-black;
          }
        }
      }
    }
  }
  &.bg-custom {
    &--mono {
      height: 100%;
      &.bg-custom--pattern {
        background-size: auto;
        background-repeat: repeat;
      }
    }
    &--gradient {
      margin: 0;
      background-size: 100% 335vh;
      background-repeat: no-repeat;
      &.bg-custom--pattern {
        background-size: auto, 100% 335vh;
        background-repeat: repeat, no-repeat;
      }
    }
    //power-reviews fix
    .product-brief__headline-review-link {
      .p-w-r .pr-snippet-stars {
        background: transparent;
      }
    }
  }
}

// White bg always when PC gnav is 'active' or in the hover state.
.active-gnav,
.active-utility-overlay {
  // html class for active nav state
  .gnav-custom {
    // body class
    .site-header {
      background-color: $color-white;
      &-formatter__inner,
      &-formatter__bar {
        background-color: $color-white;
      }
    }
    &.gnav-custom--light {
      .site-header-formatter__logo svg,
      .site-header-formatter__menu-icon svg,
      .site-header-formatter__search-icon svg,
      .site-header-formatter__locator-link svg,
      .gnav-util__icon svg,
      .gnav-cart .gnav-cart__link svg,
      .gnav-account__icon svg,
      .site-utils__icon--search {
        fill: $color-black;
      }
      a.header-nav-section__section-label,
      label.header-nav-section__section-label,
      .header-nav-section__section-label label,
      .gnav-cart .gnav-cart__link span,
      .site-header-formatter__utilities-link,
      .gnav-utility-item a.gnav-utility-item__trigger-label span {
        @include breakpoint($landscape-up) {
          color: $color-black;
          &:hover,
          &:visited {
            box-shadow: 0 1px 0 0 $color-black;
            color: $color-black;
          }
        }
      }
      &.transparent-toggle:not(.site-header-formatter--sticky),
      &:not(.site-header-formatter--sticky) {
        .site-header-formatter__logo svg,
        .site-header-formatter__menu-icon svg,
        .site-header-formatter__search-icon svg,
        .site-header-formatter__locator-link svg,
        .gnav-util__icon svg,
        .gnav-cart svg,
        .gnav-account__icon svg,
        .site-utils__icon--search {
          fill: $color-white;
        }
        a.header-nav-section__section-label,
        label.header-nav-section__section-label,
        .header-nav-section__section-label label,
        .gnav-cart .gnav-cart__link span,
        .site-header-formatter__utilities-link,
        .gnav-utility-item a.gnav-utility-item__trigger-label span {
          @include breakpoint($landscape-up) {
            color: $color-white;
            &:hover,
            &:visited {
              box-shadow: 0 1px 0 0 $color-white;
              color: $color-white;
            }
          }
        }
      }
      &.site-header-formatter--sticky {
        .content-panel-visible {
          label.header-nav-section__section-label,
          a.header-nav-section__section-label label {
            color: $color-white-op50;
          }
        }
      }
      .site-header-formatter__trigger-reset:not(:checked) ~ .site-header-formatter__sections {
        label.header-nav-section__section-label,
        label.header-nav-section__section-label .site-header-formatter__search-icon svg,
        a.header-nav-section__section-label label {
          color: $color-white-op50;
          fill: $color-white-op50;
          &:hover {
            @include breakpoint($landscape-only) {
              color: $color-white;
              fill: $color-white;
              box-shadow: 0 1px 0 0 $color-white;
              svg {
                fill: $color-white;
              }
            }
          }
        }
        .header-nav-section__trigger:checked + .header-nav-section__section .header-nav-section__section-label {
          color: $color-white;
          fill: $color-white;
          svg {
            color: $color-white;
            fill: $color-white;
          }
        }
      }
    }
  }
}
